import moment, { unitOfTime } from 'moment'

const minutesToDhms = (value: number, unit: unitOfTime.DurationConstructor) => {
  if (value === 0) {
    return '0'
  }
  const sign = Math.sign(value)
  value = Math.abs(value)
  const duration = moment.duration(value, unit)
  const d = Math.floor(duration.asDays())
  const h = Math.floor(duration.asHours() % 24)
  const m = Math.floor(duration.asMinutes() % 60)
  const dDisplay = d > 0 ? `${d}j` : ''
  const hDisplay = h > 0 ? `${h}h` : ''
  const mDisplay = m > 0 ? `${m}m` : ''
  return (sign === 1 ? '' : '-') + dDisplay + hDisplay + mDisplay
}

const yScaleFormat = (value: number, unit: unitOfTime.DurationConstructor) => {
  if (value === 0) {
    return '0'
  }
  const sign = Math.sign(value)
  value = Math.abs(value)
  const duration = moment.duration(value, unit)
  const d = Math.floor(duration.asDays())
  const h = Math.floor(duration.asHours() % 24)
  const m = Math.floor(duration.asMinutes() % 60)
  if (d > 3) {
    return `${(sign === 1 ? '' : '-')}${d}jours`
  }
  if (d === 0 && h === 0) {
    return `${(sign === 1 ? '' : '-')}${m}m`
  }
  if (d === 0) {
    return `${(sign === 1 ? '' : '-')}${h}h${m}m`
  }
  return `${(sign === 1 ? '' : '-')}${d}j${h}h${m}m`
}

const handleFormatLabel = (value: number, unit: any) => {
  if (['seconds', 'minutes'].includes(unit)) {
    return minutesToDhms(value, unit) // Humanize time
  }
  return `${value}${unit}`
}

const handleFormatLeftAxis = (value: number, unit: any) => {
  if (['seconds', 'minutes'].includes(unit)) {
    return yScaleFormat(value, unit) // Humanize time
  }
  return `${value}${unit}`
}

export {
  minutesToDhms,
  yScaleFormat,
  handleFormatLabel,
  handleFormatLeftAxis,
}
