/* eslint-disable max-len */
/* eslint-disable camelcase */
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import {
  FormGroup, FormLabel, Select, MenuItem, OutlinedInput,
  Checkbox,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { RootState } from 'reducers/store'
import getWidgetParamsValue, { sanitizePayload } from 'services/widget'
import terms from 'common/terms'
import metricMapPath from 'assets/icons/metricMap.svg'
import metricLinePath from 'assets/icons/metricChart.svg'
import metricBarPath from 'assets/icons/metricBar.svg'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomModal from 'components/CustomModal/CustomModal'
import CustomButtonCheck from 'components/CustomButtonCheck/CustomButtonCheck'
import CustomInputText from 'components/CustomInputText/CustomInputText'
import { ConfParams, ParamsCreateWidget, Widget } from 'reducers/boards/types'
import { Zone } from 'reducers/zones/types'
import BarChart from '../../Widgets/Bar/Bar'
import LineChart from '../../Widgets/Line/Line'
import Map from '../../Widgets/Map/Map'

import './WidgetCreateModal.scss'

interface Props {
  zone: Zone
  displayed: boolean;
  requestManager: RequestManager
  metricRequestManager: RequestManager
  handleClose: () => void;
  handleSubmit: (data: Partial<ParamsCreateWidget>) => void;
}

const WidgetCreateModal = ({
  zone, displayed, requestManager, metricRequestManager, handleClose, handleSubmit,
}: Props): ReactElement => {
  const { availableWidgets } = useSelector((state: RootState) => state.boards)
  const [widget, setWidget] = useState<Partial<ParamsCreateWidget>>({})
  const [displayPreview, setDisplayPreview] = useState<boolean>(false)
  const [currentFormat, setFormat] = useState<string>('')
  const [confLoading, setConfLoading] = useState<boolean>(false)
  const [metricLoading, setMetricLoading] = useState<boolean>(false)
  const [canceled, setCanceled] = useState<boolean>(false)
  const [currentMetric, setMetric] = useState<{formats: Partial<Widget>[], name: string, 'metric_slug': string}>()
  const [currentParams, setCurrentParams] = useState<ConfParams[]>([])
  const metricInfo = availableWidgets[currentMetric?.metric_slug]?.info

  const isRequiredInputsFilled = widget?.parameters?.every(param => param.value || param.value === 0)
    && !!widget?.title
    && !!widget?.widget_slug

  const handleLoadConfParams = async (payload?: {[key: string]: string}): Promise<ConfParams[]> => {
    requestManager.abort()
    return requestManager.post(
      `/usage_reseau/metric-parameters/${currentMetric.metric_slug}/${currentFormat}`,
      sanitizePayload(payload),
    )
  }

  const handleCancelCalls = () => {
    requestManager.abort()
    metricRequestManager.abort()
    setConfLoading(false)
    setMetricLoading(false)
    setCanceled(true)
  }

  useEffect(() => {
    setWidget({ widget_slug: '', parameters: [] })
    setMetric(availableWidgets.volume_trafic)
    setFormat('')
    setCurrentParams([])
    setDisplayPreview(false)
  }, [displayed])

  useEffect(() => {
    setCanceled(false)
    if (!currentFormat || !currentMetric?.metric_slug) return

    const loadParams = async () => {
      setConfLoading(true)
      const params: ConfParams[] = await handleLoadConfParams()
      setConfLoading(false)

      setCurrentParams(params.filter(param => param?.required))
      setDisplayPreview(currentFormat !== '')
      setWidget({
        ...widget,
        widget_slug: `${currentMetric.metric_slug}_${currentFormat}`,
        parameters: params.filter(param => param.required && param.slug !== 'zone_id').map(
          param => ({ slug: param.slug, value: param.default }),
        ),
      })
    }

    loadParams()
  }, [currentMetric, currentFormat])

  const handleSave = () => {
    handleSubmit(widget)
    handleClose()
  }

  const handleChange = (key: string) => async (value: string | number) => {
    setCanceled(false)
    const { parameters = [] } = cloneDeep(widget)
    const index = parameters.findIndex(param => param.slug === key)

    if (index !== -1) {
      parameters[index] = { slug: key, value }
    } else {
      parameters.push({ slug: key, value })
    }

    let newParams = cloneDeep(currentParams)
    const paramPayload = parameters.reduce((acc, param) => ({ ...acc, [param.slug]: param.value }), {})

    setConfLoading(true)
    try {
      newParams = await handleLoadConfParams({ ...paramPayload, zone_id: zone.id })
      setCurrentParams(newParams.filter(param => param.required))
    } catch (e) {
      // do nothing
    }
    setConfLoading(false)

    setWidget({
      ...widget,
      parameters: newParams.filter(param => param.required && param.slug !== 'zone_id').map(
        param => ({
          slug: param.slug,
          value: (
            paramPayload[param.slug] === null
            || paramPayload[param.slug] === undefined
            || paramPayload[param.slug] === ''
          ) ? param.default : paramPayload[param.slug],
        }),
      ),
    })
  }

  const handleCheckParams = (slug: string) => async (value: string) => {
    setCanceled(false)
    const { parameters = [] } = cloneDeep(widget)
    const index = parameters.findIndex(param => param.slug === slug)

    if (!Array.isArray(parameters[index].value)) return

    let newArray: string[] = parameters[index].value as string[]

    if (newArray.includes(value)) {
      newArray = newArray.filter(val => val !== value)
    } else {
      newArray.push(value)
    }

    parameters[index] = { slug, value: newArray }

    let newParams = cloneDeep(currentParams)
    const paramPayload = parameters.reduce((acc, param) => ({ ...acc, [param.slug]: param.value }), {})

    setConfLoading(true)
    try {
      newParams = await handleLoadConfParams({ ...paramPayload, zone_id: zone.id })
      setCurrentParams(newParams.filter(param => param.required))
    } catch (e) {
      // do nothing
    }
    setConfLoading(false)

    setWidget({
      ...widget,
      parameters,
    })
  }

  const getFormatIconForSlug = (slug: string) => {
    switch (slug) {
      case 'line-chart':
        return metricLinePath
      case 'bar-chart':
        return metricBarPath
      case 'cartography':
        return metricMapPath
      default:
        return null
    }
  }

  const renderInputForParam = (param: {
    type: string,
    slug: string,
    values: string[],
  }) => {
    const { type, slug, values } = param
    const value = getWidgetParamsValue(widget?.parameters)(slug)

    switch (type) {
      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <DatePicker
              format="DD/MM/YYYY"
              onChange={date => handleChange(slug)(date.format('YYYY-MM-DD'))}
              value={value ? moment(value) : null}
              disableFuture
              maxDate={moment().subtract(1, 'day')}
            />
          </LocalizationProvider>
        )
      case 'enum':
        return (
          <Select
            value={value}
            onChange={({ target }) => handleChange(slug)(target.value as string)}
            IconComponent={() => <UnfoldMoreIcon />}
          >
            {values.map(val => (
              <MenuItem key={val} value={val}>{val}</MenuItem>
            ))}
          </Select>
        )
      case 'multi_choice_enum':
        return (
          <CustomButtonCheck
            selected={value as unknown as string[]}
            list={values as string[]}
            handleclick={handleCheckParams(slug)}
          />
        )
      case 'int':
        return (
          <CustomInputText
            defaultValue={value as unknown as string}
            type="number"
            onChange={val => handleChange(slug)(parseInt(val, 10) || 0)}
          />
        )
      case 'bool':
        return (
          <Checkbox
            checked={value === 'true'}
            onChange={e => handleChange(slug)(e.target.checked ? 'true' : 'false')}
          />
        )
      default:
        return (
          <CustomInputText
            defaultValue={value as unknown as string}
            onChange={handleChange(slug)}
          />
        )
    }
  }

  const isFormatAvailable = (format: string) => {
    if (currentMetric.metric_slug === '<ADD_UNHANDLED_METRIC>') {
      return format === 'cartography'
    }

    return true
  }

  return (
    <CustomModal
      open={displayed}
      handleClose={handleClose}
      title={terms.Dashboard.CreateWidget.title}
      className={`
        modal-create-widget
        ${displayPreview ? ' preview-displayed' : ''}
        ${(confLoading || metricLoading) ? ' metric-loading' : ''}
      `}
      variant="primary"
    >
      <FormGroup className="form-group">
        <FormLabel className="label" required>
          {terms.Dashboard.CreateWidget.labels.name}
        </FormLabel>
        <OutlinedInput
          value={widget?.title || ''}
          onChange={({ target }) => setWidget({ ...widget, title: target.value })}
          placeholder='Ex: "Nombre de circulation"'
        />
      </FormGroup>

      <FormGroup className={`form-group${metricInfo ? ' has-info' : ''}`}>
        <FormLabel className="label" required>
          {terms.Dashboard.CreateWidget.labels.metricChoice}
        </FormLabel>
        <Select
          value={currentMetric?.metric_slug || availableWidgets?.volume_trafic?.metric_slug}
          onChange={e => setMetric(availableWidgets[e.target.value])}
          IconComponent={() => <UnfoldMoreIcon />}
        >
          {Object.values(availableWidgets).map(({ metric_slug: slug, name }) => (
            <MenuItem key={slug} value={slug}>{name}</MenuItem>
          ))}
        </Select>
      </FormGroup>

      {metricInfo && <p className="metric-info">{metricInfo}</p>}

      <FormGroup className="form-group metric-picker">
        <FormLabel>
          {terms.Dashboard.CreateWidget.labels.metricType}
        </FormLabel>
        <div className="flex-center">
          {currentMetric?.formats?.map(({ format_slug: slug, name }) => (
            <div
              key={slug}
              role="button"
              tabIndex={-1}
              className={`
                picker${currentFormat === slug ? ' selected' : ''}${!isFormatAvailable(slug) ? ' disabled' : ''}
              `}
              onClick={() => setFormat(slug)}
            >
              {getFormatIconForSlug(slug) && (
                <img
                  src={getFormatIconForSlug(slug)}
                  alt="picker"
                />
              )}
              <p>{name.split(' - ')[1]}</p>
            </div>
          ))}
        </div>
      </FormGroup>

      <>
        {currentParams?.filter(param => param.slug !== 'zone_id').map(param => (
          <FormGroup key={param.slug} className={`form-group ${param.type}`}>
            <FormLabel className="label" required>
              {param.name}
            </FormLabel>
            {renderInputForParam(param)}
          </FormGroup>
        ))}
      </>

      <CustomButton
        variant="primary"
        className="button"
        handleClick={handleSave}
        disabled={!isRequiredInputsFilled || confLoading || metricLoading}
        title={terms.Dashboard.CreateWidget.buttonValidate}
      />
      <div className="preview">
        <p>{terms.Dashboard.CreateWidget.preview}</p>
        {(confLoading || metricLoading) && (
          <CustomButton
            className="cancel-preview-button"
            title="Annuler"
            handleClick={handleCancelCalls}
            variant="borderless"
          />
        )}
        {currentFormat === 'bar-chart' && (
          <BarChart
            requestManager={metricRequestManager}
            isPreview
            isConfLoading={confLoading}
            onMetricLoad={setMetricLoading}
            canceled={canceled}
            widget={{ ...widget, metric_slug: currentMetric.metric_slug } as Widget}
            zone={zone}
          />
        )}
        {currentFormat === 'line-chart' && (
          <LineChart
            requestManager={metricRequestManager}
            isPreview
            isConfLoading={confLoading}
            onMetricLoad={setMetricLoading}
            canceled={canceled}
            widget={{ ...widget, metric_slug: currentMetric.metric_slug } as Widget}
            zone={zone}
          />
        )}
        {currentFormat === 'cartography' && (
          <Map
            requestManager={metricRequestManager}
            isPreview
            isConfLoading={confLoading}
            onMetricLoad={setMetricLoading}
            canceled={canceled}
            widget={{ ...widget, metric_slug: currentMetric.metric_slug } as Widget}
            zone={zone}
          />
        )}
      </div>
    </CustomModal>
  )
}

export default WidgetCreateModal
